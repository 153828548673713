body {
    color: #000;
    font-family: Verdana;
    margin: 0;
    padding: 0;
    border: 0;

}

html {
    scroll-behavior: smooth;
}

p,
h1,
h2,
h3 {
    color: #000;
}

a:link {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

a:visited {
    color: #000;
    text-decoration: none;
}

a:hover {
    color: #ff551a !important;
    text-decoration: none;
}

a:active {
    color: #000;
    text-decoration: none;
}

.row {
    width: auto !important;
}

.mainTemplate {
    padding: 0px;
}

div {
    box-sizing: border-box !important
}

#topbar {
    width: 100%;
    background-color: #ff551a;
    height: 20px;
    transform: translate(0px, -20px)
}

#HomepageCopy {
    box-sizing: border-box;
    width: inherit;
    font-family: "MaisonRegular";
    font-size: 2.7vw;
    line-height: 4vw;
    padding-top: 65px;
    padding-left: 8.5vw;
}

#allin {
    font-family: "MaisonBold", Helvetica, sans;
    font-size: 3.0vw;
    color: #ff551a;
    top: 44px;
    position: relative;
}

#HomepageCopyMobile {
    font-family: 'MaisonBold';
    font-size: 11vw;
    line-height: .8;
    display: none !important;
    visibility: hidden;
    width: 100%;
    margin: 0 auto;
}

.imgcopya {
    font-family: 'MaisonBold';
    font-size: 10vw;
    line-height: .8;
    letter-spacing: -2px;
    color: #000;
}

.leftHeaders {
    margin-left: 8.5vw;
    cursor: pointer;
}

.imgcopybright {
    font-family: 'MaisonMedium';
    font-size: 3.4vw;
    line-height: 1;
    margin-top: 2vw
}

.imgcopyb {
    font-family: 'MaisonMedium';
    font-size: 3.4vw;
    line-height: 1;
    margin-top: 3vw
}

.imgcopyaright {
    font-family: 'MaisonBold';
    font-size: 10vw;
    line-height: .8;
    letter-spacing: -2px;
    color: #000;
    text-transform: lowercase;
}

.rightHeaders {
    padding-right: 10vw;
    padding-top: 8vw;
    width: 100%;
    cursor: pointer;
}

#row1 {
    background-image: url("https://d2mf4l4ba7pnlp.cloudfront.net/images/greyslantbckgrnd.svg");
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;
    min-height: 1150px;
}

#imageRow2 {
    margin-top: 2vw;
}

#imageRow3 {
    margin-top: 30vw;
}

#imageRow4 {
    margin-top: 30vw;
}

#imageRow5 {
    margin-top: 30vw;
}

#imageRow6 {
    margin-top: 30vw;
}

#imageRow7 {
    margin-top: 30vw;
}

#imageRow8 {
    margin-top: 30vw;
}

#imageRow9 {
    margin-top: 30vw;
}

#textcolor2 {
    color: #000;
}

.viewIt,
.viewIt2 {
    overflow: hidden !important;
    text-align: center;
    width: 8vw;
    height: 8vw;
    background: #ff551a;
    border-radius: 50%;
    margin-left: .4vw;
    margin-top: 3vw;
    color: white;
    font-family: 'MaisonRegular'
}

.orangecopy {
    font-family: 'MaisonBold';
    text-align: center;
    margin: auto;
    width: 100%;
    /* WebKit (Safari/Chrome) Only */
    -webkit-text-stroke: .5px #fc5a16;
    /* If we weren't using text-shadow, we'd set a fallback color
and use this to set color instead
-webkit-text-fill-color: white; */
    color: #FFF;
    text-shadow: 0px 0px 0 #fc5a16,
        /* Simulated effect for Firefox and Opera and nice enhancement for WebKit */
        -1px -1px 0 #fc5a16, 1px -1px 0 #fc5a16, -1px 1px 0 #fc5a16, 1px 1px 0 #fc5a16;
    font-size: calc(1em + 3.5vw);
    letter-spacing: 4;
    line-height: 1.3;
}

hr {
    color: #ff551a;
    background-color: #ff551a;
}

#downArrow {
    display: block;
    position: relative;
    top: 37vw;
    width: 10%;
    padding: 0px;
    margin: 0 0 0 0
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

    #HomepageCopy,
    #allin {
        display: none;
    }

    #HomepageCopyMobile {
        display: block !important;
        visibility: visible !important;
        padding-top: 30%;
        padding-left: 25%;
        font-size: 14vw;
        letter-spacing: -2px;
    }

    #downArrow {
        top: -20px !important;
    }

    #row2 {
        margin-top: -15% !important;
    }

    #row1 {
        min-height: 350px;
        margin-top: -38px;
    }

    #imageRow {
        margin-top: 15vw;
    }

    #imageRow1 {
        margin-top: 15vw;
    }

    #imageRow2 {
        margin-top: 10vw;
    }

    #imageRow3 {
        margin-top: 15vw;
    }

    #imageRow4 {
        margin-top: 15vw;
    }

    #imageRow5 {
        margin-top: 25vw;
    }

    #imageRow6 {
        margin-top: 30vw;
    }

    #imageRow7 {
        margin-top: 30vw;
    }

    #imageRow8 {
        margin-top: 20vw;
    }

    #imageRow9 {
        margin-top: 27vw;
    }

    .leftHeaders {
        transform: scale(.9);
        margin-left: 25vw;
    }

    .rightHeaders {
        transform: scale(.9);
        padding-right: 38vw;
    }

    .viewIt {
        transform: scale(2.3) !important;
        margin-top: 16vw;
        margin-left: -4vw;
        line-height: .8;
    }

    .viewIt2 {
        width: 19vw;
        height: 19vw;
        margin-top: 10vw;
        margin-right: -20vw;
        margin-left: -9vw;
        line-height: 1;
    }

    #allCopyR2,
    #allCopyR6,
    #allCopyR4,
    #allCopyR8 {
        transform: scale(1.6);
    }

    #copyB2,
    #copyB4,
    #copyB6,
    #copyB8 {
        display: none;
    }

    .imgcopya {
        font-size: 10vw;
        margin-top: 11%;
    }

    .imgcopyaright {
        font-size: 10vw;
    }

    .navwrapper,
    .navwrapper2 {
        transform: scale(1.4);
    }

    .hideBreak {
        display: none;
    }

    #row0_fans {
        transform: scale(1.4);
        margin-top: 12%;
        margin-right: 11%;
    }

    .orangecopy {
        /* WebKit (Safari/Chrome) Only */
        -webkit-text-stroke: .1px #fc5a16;
        /* If we weren't using text-shadow, we'd set a fallback color
and use this to set color instead
-webkit-text-fill-color: white; */
        color: #FFF;
        text-shadow: 0px 0px 0 #fc5a16,
            /* Simulated effect for Firefox and Opera and nice enhancement for WebKit */
            -1px -1px 0 #fc5a16, 1px -1px 0 #fc5a16, -1px 1px 0 #fc5a16, 1px 1px 0 #fc5a16;
        font-size: calc(1em + 3.5vw);
        letter-spacing: 4.5;
        line-height: 1.2;
    }

    #allin {
        top: -34px;
    }

    #aRow5 {
        margin-top: 100px;
    }

    #aRow2 {
        margin-top: 100px;
        margin-bottom: -100px;
    }
}

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) and (max-width: 767.98px) {

    #HomepageCopy,
    #allin {
        display: none;
    }

    #HomepageCopyMobile {
        display: block !important;
        visibility: visible !important;
        padding-top: 90px;
        padding-left: 29%;
        font-size: 10vw;
        letter-spacing: -2px;
    }

    #downArrow {
        top: 18%;
    }

    #row1 {
        min-height: 390px;
    }

    #imageRow {
        margin-top: 15vw;
    }

    #imageRow1 {
        margin-top: 15vw;
    }

    #imageRow2 {
        margin-top: -3vw;
    }

    #imageRow3 {
        margin-top: 15vw;
    }

    #imageRow4 {
        margin-top: 15vw;
    }

    #imageRow5 {
        margin-top: 25vw;
    }

    #imageRow6 {
        margin-top: 30vw;
    }

    #imageRow7 {
        margin-top: 28vw;
    }

    #imageRow8 {
        margin-top: 20vw;
    }

    #imageRow9 {
        margin-top: 27vw;
    }

    .leftHeaders {
        transform: scale(.8) !important;
        margin-left: 15vw;
    }

    .rightHeaders {
        transform: scale(.8) !important;
        padding-right: 23vw;
    }

    .viewIt {
        transform: scale(1.4);
        margin-top: 11vw;
        margin-left: -8vw;
    }

    .viewIt2 {
        transform: scale(1.4);
        margin-top: 7vw;
        margin-right: -18vw;
    }

    #allCopyR2,
    #allCopyR6,
    #allCopyR4,
    #allCopyR8 {
        transform: scale(1.6);
    }

    #copyB2,
    #copyB4,
    #copyB6,
    #copyB8 {
        display: none;
    }

    .imgcopya {
        font-size: 9vw;
        margin-top: 11%;
    }

    .imgcopyaright {
        font-size: 9vw;
    }

    .hideBreak {
        display: none;
    }

    #row0_fans {
        transform: scale(1.4);
        margin-top: 12%;
        margin-right: 11%;
    }

    #copyB2,
    #copyB4,
    #copyB6,
    #copyB8 {
        display: none;
    }

    #aRow5 {
        margin-top: 100px;
    }
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991.98px) {

    #HomepageCopy,
    #allin {
        display: none
    }

    #HomepageCopyMobile {
        visibility: visible !important;
        display: block !important;
        padding-top: 27%;
        padding-left: 18%;
        letter-spacing: -2px;
    }

    #HomepageCopyMobile .home-copy-bold {
        max-width: 70%;
        color: #ff551a;
    }

    #row1 {
        min-height: 600px;
    }

    #imageRow {
        -webkit-margin-top: 10vw;
    }

    #imageRow1 {
        -webkit-margin-top: 10vw;
    }

    #imageRow2 {
        -webkit-margin-top: 0vw;
    }

    #imageRow3 {
        -webkit-margin-top: 10vw;
    }

    #imageRow4 {
        -webkit-margin-top: 10vw;
    }

    #imageRow5 {
        -webkit-margin-top: 20vw;
    }

    #imageRow6 {
        -webkit-margin-top: 25vw;
    }

    #imageRow7 {
        -webkit-margin-top: 15vw;
    }

    #imageRow8 {
        -webkit-margin-top: 15vw;
    }

    #imageRow9 {
        -webkit-margin-top: 22vw;
    }

    .viewIt,
    .viewIt2 {
        margin-top: -2%;
    }

    .leftHeaders {
        transform: scale(1.1) !important;
        margin-left: 16%;
    }

    .rightHeaders {
        transform: scale(1.1) !important;
        padding-right: 18%;
    }

    .imgcopybright {
        margin-top: -2%;
    }

    .imgcopyb {
        margin-top: -2%;
    }

    #downArrow {
        -webkit-top: 0vw;
    }

    #aRow5 {
        margin-top: 200px;
    }

    /*//////////////////*/
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) and (max-width: 1498px) {

    /*//////////////////*/
    #HomepageCopy {
        line-height: 1;
        display: block;
        padding-left: 9.5% !important;
    }

    #allin {
        font-size: 37px;
        line-height: 1;
        display: block
    }

    #row1 {
        min-height: 700px;
    }

    #downArrow {
        -webkit-top: 35vw;
    }

    #HomepageCopyMobile {
        display: none !important;
        visibility: hidden !important;
    }

    #aRow5 {
        margin-top: 299px;
    }

    /*//////////////////*/
}

/* Extra large devices (large desktops, 1499px and up)*/
@media (min-width: 1499px) {

    #HomepageCopy,
    #allin {
        font-size: 48px;
        line-height: 1;
        display: block;
        width: inherit;
    }

    ;

    #HomepageCopyMobile {
        display: none !important;
        visibility: hidden !important;
    }

    .imgcopya {
        font-size: 8vw !important;
    }

    .imgcopyaright {
        font-size: 8vw !important;
    }

    #imageRow2 {
        -webkit-margin-top: -270px;
    }

    #aRow5 {
        margin-top: 299px;
    }
}
.overlayParent {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	font-size: 1.5vw;
}

.overlayPrevNext {
	position: absolute;
	top: 0;
	left: calc(0% + 4%);
	right: 0;
	bottom: 0;
	width: calc(100% - 8%);
	height: 100%;
	overflow: hidden;
	font-size: 1.2vw;
}

.overlayBackTo {
	position: absolute;
	top: 0;
	left: calc(0% + 3%);
	right: 0;
	bottom: 0;
	width: calc(100% - 6%);
	height: 100%;
	overflow: hidden;
}

.overlay {
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background-color: #FC5A16;
	opacity: 0;
	overflow: hidden;
}

.bucketoverlay {
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background-color: #FC5A16;
	opacity: 0;
	overflow: hidden;
}

.imgTitles {
	opacity: 0;
	position: absolute;
	width: 100%;
	margin: 0 auto;
	top: 46%;
	padding-left: 15px;
	padding-right: 15px;
	text-align: center;
	color: white;
	font-family: 'MaisonMedium', Helvetica, sans;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
	.imgTitles {
		font-size: 5.8vw;
		opacity: 0;
	}
}

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) and (max-width: 767.98px) {
	.imgTitles {
		font-size: 5.8vw;
		opacity: 0;
	}
}


/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991.98px) {
	.imgTitles {
		font-size: 6vw;
	}
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) and (max-width: 1498px) {
	.imgTitles {
		line-height: 27px;
	}

	.overlay {
		top: 0px;
		left: 5px;
		right: 0;
		bottom: 0;
		width: 95.2%;
	}

	.bucketoverlay {
		top: 0px;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
	}

	.overlayPrevNext .imgTitles {
		line-height: 1.3vw;
	}
}

/* Extra large devices (large desktops, 1499px and up)*/
@media (min-width: 1499px) {
	.imgTitles {
		line-height: 33px;
	}

	.overlayPrevNext .imgTitles {
		line-height: 1.3vw;
	}
}
#HomepageCopy strong {
    color: #ff551a;
    top: 44px;
    position: relative;
    font-size: 37px;
    line-height: 1;
    display: block;
    font-family: 'MaisonBold', Helvetica, sans;
    font-weight: normal;
}

.orangecopy p {
    color: #fff;
}

.aRow {
    margin-top: 240px;
}

/* Listing page CSS start */
.row .col-lg-5 {
    cursor: pointer;
}
.main-logo {
    display: inline-block;
}

.hover-logo {
    display: none;
}

.main-logo:hover .default-logo {
    display: none;
}

.main-logo:hover .hover-logo {
    display: block;
}
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .listing-wrapper .imgcopyaright {
        font-size: 7vw;
        margin-top: 10px;
    }

    .listing-wrapper .imgcopybright {
        font-size: 3vw;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .listing-wrapper .imgcopyaright {
        font-size: 7vw;
        margin-top: 50px;
    }

    .listing-wrapper .imgcopybright {
        font-size: 3vw;
    }
    #copyright #ot-sdk-btn.ot-sdk-show-settings.choice-icon {
        font-size: 2vw;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .listing-wrapper .imgcopyaright {
        font-size: 7vw;
    }

    .listing-wrapper .imgcopybright {
        font-size: 3vw;
    }
}

@media (min-width: 992px) and (max-width: 1498px) {
    #footer-background {
        margin-top: 110px;
    }

    .listing-wrapper .imgcopyaright {
        font-size: 6vw;
        margin-top: 50px;
    }

    .listing-wrapper .imgcopybright {
        font-size: 3vw;
        margin-top: 2vh;
    }
}

/* Extra large devices (large desktops, 1499px and up) */
@media (min-width: 1499px) {
    #footer-background {
        margin-top: 110px;
    }

    .listing-wrapper .imgcopyaright {
        font-size: 7vw !important;
        margin-top: 100px;
    }

    .listing-wrapper .imgcopybright {
        font-size: 3vw;
        margin-top: 2vh;
    }
}

/* Listing page CSS end */
/* Details page CSS start */
.details-wrapper .subheader p {
    margin-bottom: 0px;
}

.details-wrapper .relatedText a,
.details-wrapper .relatedText2 a,
.details-wrapper .relatedText3 a {
    color: #000;
}

.details-wrapper hr {
    background-color: initial;
}

.details-wrapper .subheader a {
    color: #64CCED;
}

/* Details page CSS end */

/* Footer CSS start */
#footerCopy h2 {
    font-family: "MaisonBold", Helvetica, sans;
    font-size: 6vw;
    color: #fff;
    line-height: 1;
    margin-left: 3%;
}

#footerCopy p {
    max-width: 70% !important;
    margin-left: 3%;
}

#copyright a {
    color: black;
}

/* Footer CSS end */

/* newly added css */
@keyframes slideUpAnimation {
    from {
        opacity: 0;
        transform: translateY(60px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

main {
    overflow: hidden;
}

.homepagetext p {
    margin-bottom: 0;
}

@keyframes slideTextUp {
    from {
        opacity: 0;
        transform: translateY(30px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.homepagetext p {
    opacity: 0;
    animation: slideTextUp 1s forwards;
    animation-delay: 0.2s;
}

.listing-wrapper,
.details-wrapper {
    opacity: 0;
    animation: slideUpAnimation 2s forwards;
    animation-delay: 0.5s;
}

.homepagetext p:first-child {
    animation-delay: 1s;
}

.homepagetext p:nth-child(2) {
    animation-delay: 1.5s;
}

.homepagetext p:nth-child(3) {
    animation-delay: 2s;
}

.homepagetext p:nth-child(4) {
    animation-delay: 2.5s;
}

.homepagetext p:nth-child(5) {
    animation-delay: 3s;
}

.homepagetext p:nth-child(6) {
    animation-delay: 4s;
}

.homepagetext p:nth-child(7) {
    animation-delay: 5s;
}

.homepagetext p:nth-child(8) {
    animation-delay: 6s;
}

.homepagetext p:nth-child(9) {
    animation-delay: 7s;
}

.mob-homepagetext p:not(:last-child) {
    display: none;
}

.fade-in {
    position: relative;
    transform: translateY(75px);
    opacity: 0;
    transition: 1s all ease-out;
}

.fade-in.active {
    transform: translateY(0);
    opacity: 1;
}

.fade-in2,
.fade-in3 {
    position: relative;
    transform: translateY(30px);
    opacity: 0;
    transition: 1s all ease-out;
    transition-delay: 0.25s;
}

.fade-in2.active,
.fade-in3.active {
    transform: translateY(0);
    opacity: 1;
}

.footerTitle,
.footerParagraph {
    position: relative;
    transform: translateY(30px);
    opacity: 0;
    transition: 1s all ease-out;
}

.footerTitle.active,
.footerParagraph.active {
    transform: translateY(0);
    opacity: 1;
}

.lineanim {
    width: 0;
    margin: 2rem auto;
    transition: 1.3s all ease-out;
}

.lineanim.active {
    width: 35%;
    animation: expand .5s ease-in-out;
}

@keyframes expand {
    from {
        transform: scaleX(0);
    }

    to {
        transform: scaleX(1);
    }
}

.bucketoverlay,
.listing-imgtitles,
.related-listing-col,
.related-imgtitles{
    transition: 0.1s opacity ease-out;
}

.listing-col:hover .bucketoverlay,
.related-listing-col:hover .relatedoverlay {
    opacity: 0.8;
}

.listing-col:hover .listing-imgtitles,
.related-listing-col:hover .related-imgtitles {
    opacity: 1;
}

.related-listing-col .overlayPrevNext,
.related-listing-col .overlay {
    left: 0;
    width: 100%;
}

.details_photo iframe {
    width: 100%;
    height: 430px;
    border: none;
    outline: none;
}

/* iframe video css added */
.embed-video-container {
    position: relative;
    padding-bottom: 46.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background-color: #000;
    border-left: 15px solid #fff;
    border-right: 15px solid #fff;
}

.embed-video-container iframe,
.embed-video-container object,
.embed-video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
}

#footerCopy p.footerParagraph {
    margin-top: 20px;
    margin-bottom: 34px;
}

#footerCopy p.footerContact {
    margin-top: 0;
    margin-bottom: 0;
}

#footerCopy p#copyright {
    margin-top: 58px;
}

.page-not-found-wrap {
    padding: 10%;
}

.down-arrow {
    cursor: pointer;
}

.footer-nav-links {
    max-width: 100% !important;
    font-family: "MaisonRegular", Helvetica, sans;
}

.footer-nav-links span {
    position: relative;
}

.footer-nav-links span::after {
    content: "|";
    position: relative;
    margin: 0 4px;
}

.footer-nav-links span:last-child::after {
    display: none;
}

.footer-nav-item {
    cursor: pointer;
    position: relative;
}

#copyright #ot-sdk-btn.ot-sdk-show-settings.choice-icon.us {
    background-image: url('../../../public/assets/your-privacy-choices-icon.svg');
    background-position: left 4px top 2px;
    background-repeat: no-repeat;
    background-size: 28px 14px;
    padding-left: 38px;
}

.copyright-txt {
    font-family: "MaisonRegular", Helvetica, sans;
    padding-top: 5px;
}

@media (min-width: 1800px) {
    .copyright-txt {
        padding-top: 15px;
    }
}

@media (min-width: 1500px) {
    #copyright #ot-sdk-btn.ot-sdk-show-settings.choice-icon.us {
        background-position: left 4px top 0px;
    }
    #copyright #ot-sdk-btn.ot-sdk-show-settings.choice-icon {
        font-size: .7vw;
    }
}

@media (min-width: 2400px) {
    #copyright #ot-sdk-btn.ot-sdk-show-settings.choice-icon.us {
        background-position: left 4px top 5px;
    }
}

@media (min-width: 3300px) {
    #copyright #ot-sdk-btn.ot-sdk-show-settings.choice-icon.us {
        background-position: left 4px top 8px;
    }
}

@media (min-width: 1499px) {

    #HomepageCopy,
    #HomepageCopy strong {
        font-size: 48px;
        line-height: 1;
        display: block;
        width: inherit;
    }

    #HomepageCopy strong {
        margin-top: 0;
    }

    .leftHeaders .imgcopybright{
        margin-top: 3vw;
    }

    .details_photo iframe {
        height: 630px;
    }

    #footerCopy h2,
    #footerCopy p {
        margin-left: 0;
    }
}

@media (min-width: 1271px) {
    #detailTitle {
        /* margin-bottom: 2.2rem; */
        line-height: 110%;
    }

    .details-wrapper hr {
        margin-top: 35px;
    }
}

@media (max-width: 1200px) {

    .home-wrapper,
    .listing-wrapper {
        opacity: 0;
        animation: slideUpAnimation 2s forwards;
        animation-delay: 0.5s;
    }

    #copyright #ot-sdk-btn.ot-sdk-show-settings.choice-icon.us {
        background-size: 23px 11px;
        padding-left: 33px;
    }
}

@media (max-width: 991.98px) {

    .listing-col:hover .bucketoverlay,
    .listing-col:hover .listing-imgtitles {
        display: none;
    }

    .orangecopy p {
        margin-bottom: 0;
    }
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991.98px) {
    #copyright #ot-sdk-btn.ot-sdk-show-settings.choice-icon {
        font-size: 1.5vw;
    }
    .imgcopybright {
        margin-top: -2%;
    }

    .homerow .rightHeaders {
        transform: scale(1.1) !important;
        padding-right: 18%;
        padding-top: 8vw;
    }

    .homerow .leftHeaders {
        transform: scale(1.1) !important;
        margin-left: 16%;
        padding-top: 0;
    }

    .listing-wrapper .rightHeaders {
        padding-top: 9vw;
        padding-right: 11vw;
        transform: scale(1) !important;
    }

    .listing-wrapper .imgcopyaright {
        font-size: 7vw;
    }

    .listing-wrapper .imgcopybright {
        font-size: 3vw;
        margin-top: 2vh;

    }

    .listing-wrapper .leftHeaders {
        padding-top: 6vw;
        margin-left: 8vw;
    }

    .mobileTitles {
        font-size: 5vw;
    }

    .related-listing-col {
        width: 33%;
    }

    .related-listing-col .mobileTitles {
        font-size: 2vw;
    }

    #footerCopy p {
        max-width: 70% !important;
        padding: 0;
        margin-left: 11%;
    }

    .footerTitle h2,
    #footerCopy h2 {
        margin-left: 11%;
    }
}

@media (max-width: 575.98px) {
    #copyright #ot-sdk-btn.ot-sdk-show-settings.choice-icon {
        font-size: 3vw;
    }
    #HomepageCopyMobile .home-copy-bold {
        max-width: 86%;
        color: #ff551a;
    }

    .imgcopybright {
        display: none;
    }

    .homeallcopy {
        transform: scale(1.6) !important;
    }

    .homerow:first-of-type .fade-in,
    .homerow:first-of-type .fade-in2 {
        opacity: 1;
        transform: translateY(0);
    }

    #homepageRow0 {
        margin-top: 10vw;
    }

    .aRow {
        margin-top: 130px;
    }

    .orangecopy p {
        margin-bottom: 0;
    }

    .lineanim {
        margin: 1rem auto;
    }

    .homerow .rightHeaders {
        padding-right: 38vw;
        padding-top: 8vw;
    }

    .homerow .viewIt2 {
        margin-top: 7vw;
    }

    .home-wrapper .rightHeaders, 
    .home-wrapper .leftHeaders {
        transform: scale(.9) !important;
    }

    .leftHeaders {
        transform: scale(.9);
        margin-left: 25vw;
        top: 5vw;
    }

    .listing-wrapper .rightHeaders {
        padding-right: 28vw;
        padding-top: 4vh;
    }

    .listing-wrapper .imgcopybright {
        display: block !important;
    }

    .mobileTitles {
        font-family: 'MaisonBold';
        font-size: 5.5vw;
        letter-spacing: 0px;
        color: #FC5A16 !important;
        margin-left: 1vw;
        width: 100%;
        display: block;
        margin-top: 3vw;
    }

    .listing-wrapper .leftHeaders {
        -webkit-transform: scale(1) !important;
        -ms-transform: scale(1) !important;
        transform: scale(1) !important;
        margin-left: 23%;
        top: 3vw;
    }

    .listing-wrapper .leftHeaders .imgcopyb {
        display: block !important;
    }

    .listing-wrapper .leftHeaders .imgcopya {
        margin-top: 8px;
    }

    .related-listing-col {
        width: 50%;
    }

    .details_photo iframe {
        width: 100%;
        height: 194px;
    }

    #footerCopy h2,
    #footerCopy p {
        margin-left: 0;
        margin-bottom: 0;
    }

    #footerCopy p.footerParagraph {
        max-width: 82% !important;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    #footerCopy p.footerContact {
        margin: 0;
    }

    #footerCopy p#copyright {
        margin-top: 25px;
    }
}

/* eu cookie banner css */
.sliding-popup-bottom {
    position: fixed;
    margin: 0;
    padding: 0;
    width: 100%;
    z-index: 99999;
    left: 0;
    text-align: center;
    opacity: .95;
    background: #222 !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .5);
    height: auto;
    width: 100%;
    bottom: 0px;
}



#sliding-popup .eu-cookie-compliance-banner {
    /* height: 600px; */
}

#sliding-popup h1, #sliding-popup h2, #sliding-popup h3, #sliding-popup p, #sliding-popup label, #sliding-popup div, .eu-cookie-compliance-more-button, .eu-cookie-compliance-secondary-button, .eu-cookie-withdraw-tab {
    color: #ffffff;
    font-family: "MaisonRegular";
}

.eu-cookie-compliance-content {
    margin: 0 auto;
    max-width: 80%;
    display: inline-block;
    text-align: left;
    width: 100%;
    position: relative;
}

.eu-cookie-compliance-message {
    float: left;
    margin: 5px 0 0;
    max-width: 60%;
}

#sliding-popup .BubbleStyle_MessagesContainer {
    font-family: sans-serif;
}

#sliding-popup .BubbleStyle_MessagesContainer a {
    color: #1ad3e5;
}

.eu-cookie-compliance-buttons {
    float: right;
    margin: 0 0 10px 0;
    max-width: 40%;
}

#sliding-popup .eu-cookie-compliance-buttons button {
    background: none;
    box-shadow: inherit;
    border-radius: inherit;
    text-shadow: inherit;
    visibility: visible;
    height: 0;
    border: none !important;
    font-size: 0 !important;
}

#sliding-popup .eu-cookie-compliance-buttons button:before {
    content: "Continue";
    position: relative;
    visibility: visible;
    padding: 9px 60px 9px 30px;
    font-size: 20px;
    color: #1ad3e5;
    border: 1px solid #dcdcdc;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWBAMAAAAyb6E1AAAAG1BMVEUAAAD///8c1ucb1ucb1ecc0+Ua0+Ya1OYa0+XPHYASAAAACHRSTlMAAUpLVZ268U34ScwAAAA9SURBVAjXY2BgYGBUEmCAAuaOQBiTqaMVJswYgRAWJV3YvQMKAhkyYMwmhGgTpj6EaWQJMiMcw4RwC9SbAKRtIvLrIUAqAAAAAElFTkSuQmCC') no-repeat;
    background-position: 80%;
    font-family: 'MaisonBold';
}

.eu-cookie-compliance-message p {
    font-size: 12px;
    display: inline;
}

@media only screen and (min-width: 320px) {
    #sliding-popup .eu-cookie-compliance-message {
        max-width: 90%;
        margin-left: 10px;
    }

    #sliding-popup .BubbleStyle_MessagesContainer {
        font-size: 18px;
        padding-top: 36px;
        padding-bottom: 20px;
        line-height: 25px;
    }

}

@media only screen and (min-width: 751px) {
    #sliding-popup .eu-cookie-compliance-banner {
        /* height: 426px; */
    }

    #sliding-popup .eu-cookie-compliance-message {
        max-width: 62%;
        margin-left: 0;
    }

    #sliding-popup .BubbleStyle_MessagesContainer {
        font-size: 1.8vw;
        padding-top: 48px;
        padding-bottom: 20px;
        line-height: 3.3vw;
    }

    #sliding-popup .eu-cookie-compliance-buttons {
        max-width: 25%;
        padding-top: 70px;
        padding-left: 0;
    }
}

@media only screen and (min-width: 1271px) {
    #sliding-popup .eu-cookie-compliance-banner {
        /* height: 244px; */
    }

    #sliding-popup .eu-cookie-compliance-message {
        max-width: 80%;
        margin-left: 0;
    }

    #sliding-popup .BubbleStyle_MessagesContainer {
        font-size: 1.5vw;
        padding-top: 54px;
        padding-bottom: 20px;
        line-height: 2.5vw;
    }

    #sliding-popup .eu-cookie-compliance-buttons {
        max-width: 20%;
        margin-top: 63px;
        padding-left: 0;
        padding-top: 0;
    }
}

@media only screen and (min-width: 1920px) {
    #sliding-popup .BubbleStyle_MessagesContainer {
        font-size: 1.2vw;
        line-height: 2.3vw;
        padding-top: 64px;
        padding-bottom: 20px;
    }

    #sliding-popup .eu-cookie-compliance-buttons {
        margin-top: 74px;
    }
}

@media screen and (max-width: 767px) {
    .eu-cookie-compliance-content {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #sliding-popup .eu-cookie-compliance-buttons {
        max-width: 100%;
        padding-top: .625rem;
        padding-left: 0;
        height: 100%;
        margin-bottom: 36px;
    }
}
span.us.cookie-notice {
    display: none;
}
span.non-us.privacy-choice {
    display: none;
}
#copyright #ot-sdk-btn.ot-sdk-show-settings {
  border: none;
  color: #212529;
  background-color: unset;
  padding: 0;
  font-size: 1vw;
}
#onetrust-consent-sdk #onetrust-pc-sdk .ot-pc-header {
    min-height: 39px;
    height: auto;
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-hdr {
    height: auto;
    width: 100%;
}
#onetrust-pc-sdk #ot-pc-lst .ot-acc-cntr > .ot-acc-hdr {
    width: 100%;
}

/* CSS Document */
body {
  opacity: 1 !important;
}

a:link {
  color: #FFF;
  text-decoration: none;
  cursor: pointer;
}

a:visited {
  color: #FFF;
  text-decoration: none;
}

a:active {
  color: #FFF;
  text-decoration: none;
}

/* OPEN BUTTON */
.navwrapper {
  position: absolute;
  right: 18px;
  top: 0px;
  z-index: 5002;
  cursor: pointer;
  z-index: 9998;
  opacity: 0;
}

.nav div {
  height: 6px;
  background: #000000;
  margin: 5px 0 0;
  border-radius: 26px;
  transition: .3s;
}

.nav {
  position: relative;
  top: 31%;
  left: 23%;
  display: block;
  width: 59%;
  height: 59%;
}

.one {
  position: relative;
  width: 75%;
}

.two {
  position: relative;
  width: 100%;
}

.three {
  position: relative;
  width: 75%;
  float: right;
}

.circle {
  position: relative;
  display: block;
  border: 6px solid #000000;
  height: 100px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 100px;
}

.circle:hover {
  border: 6px solid #fc5a16;
}

.circle:hover .one {
  width: 100%;
  background: #fc5a16;
}

.circle:hover .two {
  width: 100%;
  background: #fc5a16;
}

.circle:hover .three {
  width: 100%;
  background: #fc5a16;
}

/*CLOSE BUTTON*/
.navwrapper2 {
  position: absolute;
  right: 18px;
  top: 0px;
  z-index: 5001;
  cursor: pointer;
}

.nav2 div {
  height: 6px;
  background: #000000;
  margin: 5px 0 0;
  border-radius: 26px;
}

.nav2 {
  position: relative;
  top: 31%;
  left: 23%;
  display: block;
  width: 59%;
  height: 59%;
}

.one2 {
  position: relative;
  width: 100%;
}

.two2 {
  position: relative;
  width: 100%;
}

.three2 {
  position: relative;
  width: 100%;
}

.circle2 {
  position: relative;
  display: block;
  border: 6px solid #000000;
  height: 100px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 100px;
}

#closeWrapper {
  display: none;
  z-index: 9999
}

/*THE OPEN MENU*/
#openmenu {
  display: none;
  z-index: 1029
}

.menucopy {
  font-family: "MaisonMedium", Helvetica, sans;
  font-size: 40px;
  color: #FFF;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  text-align: center;
  text-transform: lowercase;
}

.parallelogram {
  display: block;
  width: 100%;
  height: 215px;
  opacity: 0;
  background: #fc5a16;
  -moz-transform: skew(0deg, -9deg);
  -webkit-transform: skew(0deg, -9deg);
  -o-transform: skew(0deg, -9deg);
  -ms-transform: skew(0deg, -9deg);
  transform: skew(0deg, -9deg);
}

.fresh-baked {
  background: #64CCED;
}

#mStripe0 {
  top: 138px;
}

#mStripe1 {
  top: 311px
}

#mStripe2 {
  top: 484px
}

#mStripe3 {
  top: 657px
}

#mStripe4 {
  top: 830px
}

#mc0 {
  opacity: 0;
  position: absolute;
  top: 198px;
  cursor: pointer;
}

#mc1 {
  opacity: 0;
  position: absolute;
  top: 374px;
  cursor: pointer;
}

#mc2 {
  opacity: 0;
  position: absolute;
  top: 546px;
  cursor: pointer;
}

#mc3 {
  opacity: 0;
  position: absolute;
  top: 721px;
  cursor: pointer;
}

#mc4 {
  opacity: 0;
  position: absolute;
  top: 888px;
  cursor: pointer;
}

.pholder {
  width: 100%;
  height: 13.2vw;
  position: absolute;
}

#ph1 {
  top: 1%;
}

#ph2 {
  top: 15%;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

  .circle,
  .circle2 {
    width: 70px;
    height: 70px;
    border: 3px solid #000000;
  }

  .nav div {
    height: 4px;
    margin: 5px 0 0
  }

  .nav2 div {
    height: 4px;
    margin: 6px 0 0;
  }

  .nav2 {
    top: 23%;
    left: 22%;
  }

  .nav {
    position: relative;
    top: 25%;
    left: 23%
  }

  .navwrapper,
  #closeWrapper {
    position: absolute;
    right: 5px;
    top: 3.5vh;
    -webkit-transform: scale(0.7) !important;
    -ms-transform: scale(0.7) !important;
    transform: scale(0.7) !important;
  }

  .circle:hover {
    border: 3px solid #fc5a16;
  }

  .parallelogram {
    height: 100px;
  }

  #mStripe0 {
    top: 43px !important
  }

  #mStripe1 {
    top: 145px !important
  }

  #mStripe2 {
    top: 247px !important
  }

  #mStripe3 {
    top: 349px !important
  }

  #mStripe4 {
    top: 451px !important
  }

  #mc0 {
    opacity: 0;
    position: absolute;
    top: 82px
  }

  #mc1 {
    opacity: 0;
    position: absolute;
    top: 182px
  }

  #mc2 {
    opacity: 0;
    position: absolute;
    top: 282px
  }

  #mc3 {
    opacity: 0;
    position: absolute;
    top: 385px
  }

  #mc4 {
    opacity: 0;
    position: absolute;
    top: 485px
  }

  .menucopy {
    font-size: 21px;
  }

  #LINE1 {
    transform-origin: 40% center;
  }

  #LINE3 {
    transform-origin: 40% center;
  }

  #openmenu {
    top: -5px;
  }
}

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 577px) and (max-width: 767.98px) {

  .circle,
  .circle2 {
    width: 70px;
    height: 70px;
    border: 3px solid #000000;
  }

  .nav div {
    height: 4px;
    margin: 5px 0 0
  }

  .nav2 div {
    height: 4px;
    margin: 6px 0 0;
  }

  .nav2 {
    top: 23%;
    left: 22%;
  }

  .nav {
    position: relative;
    top: 25%;
    left: 23%
  }

  .navwrapper,
  #closeWrapper {
    position: absolute;
    right: 5px;
    top: 3.5vh;
    -webkit-transform: scale(0.7) !important;
    -ms-transform: scale(0.7) !important;
    transform: scale(0.7) !important;
  }

  .circle:hover {
    border: 3px solid #fc5a16;
  }

  .parallelogram {
    height: 100px;
  }

  #mStripe0 {
    top: 43px !important
  }

  #mStripe1 {
    top: 145px !important
  }

  #mStripe2 {
    top: 247px !important
  }

  #mStripe3 {
    top: 349px !important
  }

  #mStripe4 {
    top: 451px !important
  }

  #mc0 {
    opacity: 0;
    position: absolute;
    top: 85px
  }

  #mc1 {
    opacity: 0;
    position: absolute;
    top: 185px
  }

  #mc2 {
    opacity: 0;
    position: absolute;
    top: 285px
  }

  #mc3 {
    opacity: 0;
    position: absolute;
    top: 385px
  }

  #mc4 {
    opacity: 0;
    position: absolute;
    top: 485px
  }

  .menucopy {
    font-size: 21px;
  }

  #LINE1 {
    transform-origin: 40% center;
  }

  #LINE3 {
    transform-origin: 40% center;
  }
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991.98px) {

  .circle,
  .circle2 {
    width: 70px;
    height: 70px;
    border: 3px solid #000000;
  }

  .nav,
  .nav2 {
    top: 18%;
  }

  .nav div {
    height: 4px;
    margin: 5px 0 0
  }

  .nav2 div {
    height: 4px;
    margin: 6px 0 0;
  }

  .nav2 {
    top: 23%;
    left: 22%;
  }

  .nav {
    position: relative;
    top: 25%;
    left: 23%
  }

  .navwrapper,
  #closeWrapper {
    position: absolute;
    right: 25px;
    top: 5vh;
    -webkit-transform: scale(1) !important;
    -ms-transform: scale(1) !important;
    transform: scale(1) !important;
  }

  .circle:hover {
    border: 3px solid #fc5a16;
  }

  .parallelogram {
    height: 130px;
  }

  #mStripe0 {
    top: 74px !important
  }

  #mStripe1 {
    top: 206px !important
  }

  #mStripe2 {
    top: 339px !important
  }

  #mStripe3 {
    top: 472px !important
  }

  #mStripe4 {
    top: 605px !important
  }

  .menucopy {
    font-size: 30px;
  }

  #mc0 {
    opacity: 0;
    position: absolute;
    top: 124px;
  }

  #mc1 {
    opacity: 0;
    position: absolute;
    top: 252px;
  }

  #mc2 {
    opacity: 0;
    position: absolute;
    top: 385px;
  }

  #mc3 {
    opacity: 0;
    position: absolute;
    top: 518px;
  }

  #mc4 {
    opacity: 0;
    position: absolute;
    top: 648px;
  }

  #LINE1 {
    transform-origin: center center;
  }

  #LINE3 {
    transform-origin: center center;
  }
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) and (max-width: 1498px) {
  #LINE1 {
    transform-origin: center center;
  }

  #LINE3 {
    transform-origin: center center;
  }

  .circle,
  .circle2 {
    width: 70px;
    height: 70px;
    border: 4px solid #000000;
  }

  .nav,
  .nav2 {
    top: 18%;
  }

  .nav div {
    height: 4px;
    margin: 5px 0 0
  }

  .nav2 div {
    height: 4px;
    margin: 6px 0 0;
  }

  .nav2 {
    top: 23%;
    left: 22%;
  }

  .nav {
    position: relative;
    top: 25%;
    left: 23%
  }

  .navwrapper,
  #closeWrapper {
    position: absolute;
    right: 15px;
    top: 5vh
  }

  .circle:hover {
    border: 4px solid #fc5a16;
  }

  .menucopy {
    font-size: 28px;
  }

  .parallelogram {
    height: 110px;
  }

  #mc0 {
    opacity: 0;
    position: absolute;
    top: 122px
  }

  #mc1 {
    opacity: 0;
    position: absolute;
    top: 235px
  }

  #mc2 {
    opacity: 0;
    position: absolute;
    top: 348px
  }

  #mc3 {
    opacity: 0;
    position: absolute;
    top: 461px
  }

  #mc4 {
    opacity: 0;
    position: absolute;
    top: 574px
  }

  #mStripe0 {
    top: 88px !important
  }

  #mStripe1 {
    top: 201px !important
  }

  #mStripe2 {
    top: 314px !important
  }

  #mStripe3 {
    top: 427px !important
  }

  #mStripe4 {
    top: 540px !important
  }

  #openmenu {
    top: 10px;
  }
}

/* Extra large devices (large desktops, 1499px and up)*/
@media (min-width: 1499px) {

  .navwrapper,
  .navwrapper2 {
    position: absolute;
    right: 18px;
    top: 70px;
    z-index: 60000;
  }

  .menucopy {
    font-size: 28px;
  }

  .parallelogram {
    height: 110px;
  }

  #mc0 {
    opacity: 0;
    position: absolute;
    top: 172px
  }

  #mc1 {
    opacity: 0;
    position: absolute;
    top: 285px
  }

  #mc2 {
    opacity: 0;
    position: absolute;
    top: 398px
  }

  #mc3 {
    opacity: 0;
    position: absolute;
    top: 511px
  }

  #mc4 {
    opacity: 0;
    position: absolute;
    top: 624px
  }

  #mStripe0 {
    top: 138px !important
  }

  #mStripe1 {
    top: 251px !important
  }

  #mStripe2 {
    top: 364px !important
  }

  #mStripe3 {
    top: 477px !important
  }

  #mStripe4 {
    top: 590px !important
  }
}

/* newly added */
.menu-row-first {
  padding: 0;
  margin: 0;
  min-height: 1500px;
}

.menu-row-second {
  padding-top: 8.5vw;
}

.menu-types {
  position: absolute;
  top: 0px;
  width: 100%;
}

.menucopy:hover {
  color: #000 !important;
}
@font-face {
    font-family: 'MaisonMedium';
    src: url('../fonts/MaisonMedium.eot');
    src: url('../fonts/MaisonMedium.eot') format('embedded-opentype'),
         url('../fonts/MaisonMedium.woff2') format('woff2'),
         url('../fonts/MaisonMedium.woff') format('woff'),
         url('../fonts/MaisonMedium.ttf') format('truetype'),
         url('../fonts/MaisonMedium.svg#MaisonMedium') format('svg');
}


@font-face {
    font-family: 'MaisonRegular';
    src: url('../fonts/MaisonRegular.eot');
    src: url('../fonts/MaisonRegular.eot') format('embedded-opentype'),
         url('../fonts/MaisonRegular.woff2') format('woff2'),
         url('../fonts/MaisonRegular.woff') format('woff'),
         url('../fonts/MaisonRegular.ttf') format('truetype'),
         url('../fonts/MaisonRegular.svg#MaisonRegular') format('svg');
}



@font-face {
    font-family: 'MaisonBold';
    src: url('../fonts/MaisonBold.eot');
    src: url('../fonts/MaisonBold.eot') format('embedded-opentype'),
         url('../fonts/MaisonBold.woff2') format('woff2'),
         url('../fonts/MaisonBold.woff') format('woff'),
         url('../fonts/MaisonBold.ttf') format('truetype'),
         url('../fonts/MaisonBold.svg#MaisonBold') format('svg');
}

/*@import url('css/bootstrap_cust.css');*/
body {color:#000;
margin:0;
padding:0;
border:0;
}
p, h1, h2, h3 {color:#000;}
/* a:link { color:#000; text-decoration: none; cursor:pointer; }
a:visited { color:#000; text-decoration: none; }
a:hover { color:#ff551a !important; text-decoration: none;}
a:active { color:#000; text-decoration: none; } */

/* .container, .row,  div { overflow:hidden !important;margin:0 auto; padding:0px; border:0px;width: auto !important;  max-width: 1500px !important} */

.row{width: auto !important; }

.mainTemplate {padding:0px;}
.imgcol { /*overflow:hidden;*/ }
 
 div {box-sizing: border-box !important}
 #topbar {width:100%; background-color: #ff551a; height: 20px;}

.imgcopya { margin-top: 10%;font-family: 'MaisonBold';line-height: .8;letter-spacing: -2px; color: #000;    margin-left: 4vw;     width: 100%;}
.imgcopyb {font-family:'MaisonMedium'; line-height: 1; margin-top:1.5vw;/* SEE EACH BUCKET PAGE HTML INLINE  // DIFFERENT FOR EACH PAGE*/ margin-left: 4vw; width: 100%;}
.imgcopybright { font-family:'MaisonMedium'; font-size:3.4vw;line-height: 1; margin-top: 2vw; text-align:right;}
.listing-wrapper .leftHeaders { margin-left:4.5vw;  padding-top: 1vw;  }
.listing-wrapper .imgcopyaright { font-family: 'MaisonBold';font-size: 10vw; line-height: .8;letter-spacing: -2px; color: #000; text-align:right;text-transform: lowercase;}
.listing-wrapper .rightHeaders { padding-right:8vw; padding-top: 1vw; width:100%; cursor:pointer; }
#imageRow0 {margin-top:1vh;}
#textcolor2{color:#000;}
.photoRowBottom {margin-top:1.5vw}
.photoRow {margin-top:6vw;}
.photoRowsExtra{margin-top:1.5vw;}

.moreBtns {width:25%; height:25%;}
.moreBtnRow {padding-top:4vw;}
.photoRowsExtra {margin-top:-2.5vw}
#thinkersPhotosTop {padding-top: 7vw}


.mobileTitles {display:none; color: #FC5A16 !important; margin-bottom:10vw !important;}


/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
.photoRow{margin-top:27vw !important;}
/*//////////////////*/
.leftHeaders{-webkit-transform:  scale(1) !important;-ms-transform: scale(1) !important;transform:  scale(1) !important;;margin-left: 23%;top: 9vw;}
.imgcopyb {line-height: 1.1;margin-top: -3vw;margin-left: 14%;}
#imageRow0 { -webkit-transform:  scale(1.4) !important;-ms-transform: scale(1.4) !important;transform:  scale(1.4) !important; padding-right: 14vw;}
.mobileTitles {font-family:'MaisonBold'; font-size: 5.5vw; letter-spacing: 0px; color: #FC5A16 !important;  margin-left: 1vw;width: 100%; display:block; margin-top: 3vw;}
.topImg {margin-bottom:1vw;}
.rightHeaders {padding-right: 28vw;-webkit-transform:  scale(1) !important;-ms-transform: scale(1) !important;transform:  scale(1) !important;  }
.imgcopybright {margin-top: -2vh;}
.rightHeaders {padding-top: 4vh;}
/*//////////////////*/
}


/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) and (max-width: 767.98px) { 
/*//////////////////*/
.photoRow{margin-top:6vw !important;}
.leftHeaders{    -webkit-transform:  scale(1.1) !important;-ms-transform: scale(1.1) !important;transform:  scale(1.1) !important; padding-top: 9vw; margin-left: 8vw;}
.topImg {margin-bottom:1vw;}
.imgTitles {font-size:5.8vw;opacity:1;}
.mobileTitles {font-family:'MaisonBold'; font-size: 5vw; letter-spacing: 0px; color: #FC5A16 !important;    margin-left: 1vw;width: 100%; display:block;}

.photoRowBottom {margin-top:1vw !important;}
.rightHeaders { padding-right:15vw; -webkit-transform:  scale(1.2) !important;-ms-transform: scale(1.2) !important;transform:  scale(1.2) !important;  }
/*//////////////////*/
}


/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991.98px) { 
/*//////////////////*/
.topImg {margin-bottom:1vw;}
.imgTitles {font-size:6vw;}
.mobileTitles {font-family:'MaisonBold'; font-size: 5vw; letter-spacing: 0px; color: #FC5A16 !important;    margin-left: 1vw;width: 100%; display:block;}
.rightHeaders  {padding-top: 9vw; padding-right: 11vw;}
.leftHeaders { padding-top: 11vw; margin-left: 6vw;}
#thinkersPhotosTop {padding-top: 25vw;}
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) and (max-width: 1498px) 
{ 
	.overlay {top: 0px;left: 5px;right: 0;bottom: 0;width: 97.2%;}
	.imgcopya {font-size:10vw;}
	.photoRowBottom {margin-top:1.85vw}
	.rightHeaders {padding-right: 7vw !important;}
	.leftHeaders { padding-top: 9vw;}
}
	
/* Extra large devices (large desktops, 1499px and up)*/
@media (min-width: 1499px) 
{ 
	.imgcopya {font-size:11vw;}
	.imgcopyb {font-size:11vw;}
	#thinkersPhotosTop {padding-top: 5vw}
}

/* We are thinking style */
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) 
{ 
	.imgcopya {font-size:7vw; margin-top: 10px;}
	.imgcopyb {font-size:3vw; }
}

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) and (max-width: 767.98px) 
{ 
	.imgcopya {font-size:7vw; margin-top: 50px;}
	.imgcopyb {font-size:3vw; }
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991.98px) 
{ 
	.imgcopya {font-size:7vw;}
	.imgcopyb {font-size:3vw; }
}

@media (min-width: 992px) and (max-width: 1498px) 
{ 
	#footer-background {margin-top: 110px;}
	.imgcopya {font-size:6vw; margin-top: 50px;}
	.imgcopyb {font-size:3vw;}
}
/* Extra large devices (large desktops, 1499px and up)*/
@media (min-width: 1499px) 
{ 
	#footer-background {margin-top: 110px;}
	.imgcopya {font-size:7vw; margin-top: 100px; }
	.imgcopyb {font-size:3vw; }
}

	
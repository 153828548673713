/*//////////////////////////////////////////*/
/*@import url('../bootstrap_cust.css');*/
body {color:#000;
margin:0;
padding:0;
border:0;
}
.relatedphotoRow .col-lg-3 {cursor: pointer;}
.relatedphotoRow .col-lg-4 {cursor: pointer;}
.relatedphotoRow .col-lg-5 {cursor: pointer;}
p, h1, h2, h3 {color:#000;}
a:link { color:#000; text-decoration: none; cursor:pointer; }
a:visited { color:#000; text-decoration: none; }
a:hover { color:#000; text-decoration: none; }
a:active { color:#000; text-decoration: none; }
#footer-background {margin-top: 120px;}
/*////////////////////////////////////////////////*/
/* .container, .row,  div {padding-right:0px !important; padding-left:0px !important; overflow:hidden !important;margin:0 auto; padding:0px; border:0px;width: auto !important;  max-width: 1500px !important} */
/* .row{width: auto !important;     margin-left: 0px !important; margin-right: 0px !important; }  */
div {box-sizing: border-box !important}
.mobileTitles {display:none; color: #FC5A16 !important; margin-bottom:10vw !important;}
.mobileTitles p {color: #FC5A16; line-height: 120%;}
#topbar {width:100%; background-color: #ff551a; height: 20px;}
#detailPageTop {width:100%; /*background-color: #ffffff;*/ height: 50px;}
.details_photo { padding-left: 1.5%;padding-right: 1.5%;}
.photoRow0_details {margin-top:1vw;}
#titleSpacer {display:none;}
.headerTitle {color:#FC5A16; width:100%;}
hr {width:100%; color:#000 !important; margin-bottom:1vw;margin-top:2vw}
.photoRow0{margin-top:59vw !important;}
.relatedProjects { font-family: 'MaisonBold';font-size: 3vw;line-height: 1;width: 100%;}

.bucketTile {background-color: #767676; color: white; font: 'MaisonBold'; font-size: 2vw; margin: 0 auto;}
.bucketTile:hover {background-color:#FC5A16;}
/* #closeWrapper {padding-right: 2%;} */
/* #menuBtn {padding-right: 2%;} */


#titleRow0 {top:-3vw; /*background-color:#FFF !important;*/ width:100% !important}

#textcolor2{color:#000;}
.relatedphotoRow {margin-top:0vw !important;margin-bottom:0vw;}
.overlayParent{ position: absolute;top: 0;left: calc(0% + 2.4%);right: 0;bottom: 0;width: calc(100% - 4.8%);height: 100%;overflow: hidden;}
.overlay {background-blend-mode: hard-light; position: absolute;top: 0px;left:0px;right: 0;bottom: 0;width: 100%;height: 100%;background-color: #FC5A16;opacity: 0;overflow: hidden;/* transform: rotate(-37deg); */}
.pinnedHeader {     position: relative; top: -57px; left: -54px;}
#detailTitle {margin-top:14%;font-family: 'MaisonBold';font-size: 6vw;line-height: 1;width: 100% !important;padding-left: 0%;}
#subheaderDiv {margin-top:0vw !important; margin-top:1.5vw !important;height:11vw;}
#hrTopDetail {width:80%; color:#000 !important; margin-bottom:2vw; text-align:center; margin-top:2.5vw}

/*Graphic Elements*/
.SubsectionRule {margin-top: 75px;}

/* Text Styles */
.SubsectionTitle {font-size: 32px; color:#64CCED; font-family: 'MaisonBold'; margin-top: 20px;}
.SubsectionDescription {font-size: 16px; font-family: 'MaisonRegular';}



.detailCopy {margin-bottom: 40px;}
.subheader {font-family: 'MaisonRegular',Helvetica Bold, sans-bold; font-size: .9vw; color:#000; line-height:1.3;}
.SubheadLabel {font-family:'MaisonBold';}
#sh1{font-family: 'MaisonBold',Helvetica Bold, sans-bold}
#sh2 {text-transform: uppercase;}
#sh3 {text-transform: uppercase;}

.relatedText {font-family: 'MaisonBold';font-size: 1vw; text-transform: uppercase; line-height: 1;width: 100%; color: black; text-align: left;}
.relatedText2 {font-family: 'MaisonBold';font-size: 1vw; text-transform: uppercase; line-height: 1;width: 100%; color: black; text-align: right;}
.relatedText3 {font-family: 'MaisonRegular';font-size: 1vw; text-transform: uppercase; line-height: 1;width: 100%; color: gray; text-align: right;}
.relatedText:hover {color: #FC5A16; cursor: pointer;}
.relatedText2:hover {color: #FC5A16; cursor: pointer;}
.relatedText3:hover {color: #FC5A16; cursor: pointer;}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) 
{
	.imgTitles {opacity:1;}
	.mobileTitles {font-family:'MaisonBold'; font-size: 3vw; line-height: .8;letter-spacing: 0px; color: #FC5A16; ;width: 100%; display:block;}
	.subheader { font-size: 3.5vw; line-height: 1.3; padding-right: 3%; margin-bottom: 7vw; padding-left: 8%;  }
	#subheaderDiv {padding-bottom:3vw !important; margin-top: 4vw !important; height: 129vw; height: 110vw !important; }
	#detailTitle {margin-top: 33%;font-size:9vw;padding-left: 7%; }
	#hrTopDetail  {width:92% !important;margin-top: 0vw;    margin-bottom: 5vw;}
	.relatedText {font-size: 3vw;}
	.relatedText2 {font-size: 3vw;}
	.relatedText3 {font-size: 1.6vw; display: none;}
	#titleRow0 {top: 2vw;width: 100% !important;}
	#rp2 {width: 50%;}
	#rp3 {width: 50%;}
	#sh1, #sh2, #sh3 {padding-left: 45px; padding-right: 65px;}

	.SubsectionTitle {padding-left: 8%;}
	.SubsectionDescription {padding-left: 8%;}
}
/*//////////////////*/


/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 577px) and (max-width: 767.98px) 
{
	.photoRow0{margin-top:40vw !important;}
	.topImg {margin-bottom:1vw;}
	.imgTitles {font-size:5.8vw;opacity:1;}
	.mobileTitles {font-family:'MaisonBold'; font-size: 2vw; line-height: .8;letter-spacing: 0px; color: #FC5A16 !important;    margin-left: 1vw;width: 100%; display:block;}
	#subheaderDiv {margin-top:0vw !important; height: 78vw !important;}
	.subheader { font-size: 2.3vw; line-height: 1.3; padding-right: 3%; margin-bottom: 2vw; padding-left: 10%; padding-right: 19%;}
	.relatedProjects { font-size: 6vw; margin-left: 2%;}
	#detailTitle {padding-top: 10%; padding-left: 7%}
	.relatedText {font-size: 2vw;}
	.relatedText2 {font-size: 2vw;}
	.relatedText3 {font-size: 1.2vw; display: none;}
	#rp2 {width: 50%;}
	#rp3 {width: 50%;}

	.SubsectionTitle {padding-left: 10%;}
	.SubsectionDescription {padding-left: 10%;}
}
/*//////////////////*/

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991.98px) 
{
	.topImg {margin-bottom:1vw;}
	hr {width:100%;}
	.subheader { font-size: 2.5vw;line-height: 1.3;padding-right: 11% !important; margin-bottom: 2vw; padding-left: 11% !important;}
	#subheaderDiv {padding-left: 3%; margin-top:-2vw !important;height:74vw;}
	.relatedProjects {padding-top:10%;}
	#detailTitle {margin-top: 33%;padding-left: 9%;}
	.mobileTitles {font-family:'MaisonBold'; font-size: 2vw; line-height: .8; letter-spacing: 0px; color: #FC5A16 !important; width: 100%; display:block;}
	.relatedText {font-size: 1.3vw;}
	.relatedText2 {font-size: 1.3vw;}
	.relatedText3 {font-size: 1.3vw;}
	#rp2 {width: 33%;}
	#rp3 {width: 33%;}

	.SubsectionTitle {padding-left: 11%;}
	.SubsectionDescription {padding-left: 11%;}
}
/*//////////////////*/

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) and (max-width: 1498px) 
{ 
	#detailTitle { font-size:7.5vw;   margin-top: 23%;}
	.relatedProjects {font-size:5vw}
	.overlay {top: 0px;left: 5px;right: 0;bottom: 0;width: 97.2%;}
	#subheaderDiv {margin-top:0vw !important; margin-top:-2vw !important;height:15vw;}
	.subheader {font-size: 1.3vw;}
	#hrTopDetail  {margin-top: 5vw;    margin-bottom: 5vw;}
}
/*//////////////////*/


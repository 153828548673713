#verticallogo {
    cursor: pointer !important;
    position: absolute;
    margin-right: 0px;
    padding-top: 50px;
    left: 4%;
    display: block;
    z-index: 1018;
    opacity: 0;
}

#logoAnim {
    position: absolute;
    top: 30%;
    left: 24%;
    opacity: 0;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    #verticallogo {
        position: absolute;
        padding-top: 0;
        padding-left: 0;
        left: -5%;
        top: -18px;
    }

    #logo {
        position: absolute;
        -webkit-transform: scale(0.7);
        -ms-transform: scale(0.7);
        transform: scale(0.7);
        left: 23px;
        position: absolute;
    }

    #logoAnim {
        display: none;
        position: absolute;
    }
}

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 577px) and (max-width: 767.98px) {
    #verticallogo {
        position: absolute;
        padding-top: 0;
        padding-left: 0;
        left: -5%;
    }

    #logo {
        -webkit-transform: scale(0.8) !important;
        -ms-transform: scale(0.8) !important;
        transform: scale(0.8) !important;
        top: 8px;
        left: 40px;
        position: relative;
    }

    #logoAnim {
        display: none;
    }
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991.98px) {
    #verticallogo {
        left: 5%;
    }
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) and (max-width: 1498px) {
    #verticallogo {
        left: 4%;
    }
}
html {
    height: 100% !important;
}

body {
    height: 100% !important;
}

#LandscapeMessage {
    background-color: #FC5A16;
    color: white;
    display: none;
    height: 100%;
    width: 100%;
    text-align: center;
    padding-top: 20%;
    margin: auto;
    font-family: 'MaisonMedium';
    font-size: 5.5vw;
    line-height: 1;
    letter-spacing: 0px;
}
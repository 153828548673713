a:link {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

a:visited {
    color: #000;
    text-decoration: none;
}

a:hover {
    color: #ff551a !important;
    text-decoration: none;
}

.weblink:hover {
    color: gray !important;
    text-decoration: none;
}

a:active {
    color: #000;
    text-decoration: none;
}


.footerTitle {
    font-family: "MaisonBold", Helvetica, sans;
    font-size: 6vw;
    color: #fff;
    line-height: 1;
}

#myFooter {
    background-color: #64CCED;
    bottom: 0px;
    padding-top: 7%;
    bottom: 0;
    padding-right: 0vw;
    padding-bottom: 0vw;
    height: 1100px;
}

#footer-background {
    position: relative;
    background-color: #64CCED;
    height: 230px;
    transform: skewY(-9deg);
    transform-origin: bottom right;
    overflow: hidden;
}

#footerCopy {
    font-family: "MaisonRegular", Helvetica, sans;
    font-size: 1.4em;
    color: #000;
    line-height: 1.5;
    margin-top: 0%;
}

#copyright,
.copyright {
    font-size: .7vw;
    padding-top: 0%;
    margin-top: -1vw;
    display: block;
}

.footerParagraph {
    display: block;
    max-width: 63% !important;
}

.footerContact {
    color: #fff;
}

.msplitScroll {
    line-height: 1.2;
    margin: -10 !important;
}

.footerRow {
    overflow: hidden;
    padding: 0;
}

.footerSides {
    background-color: #64CCED;
    padding: 0;
}


/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .footerTitle {
        font-size: 11vw !important;
    }

    #footerCopy {
        font-size: .9em !important;
        line-height: 1.23;
        margin-top: 0;
    }

    .footerRow {
        margin-top: -6vw;
    }

    #myFooter {
        height: auto;
        padding-top: 2%;
        padding-left: 15% !important;
        padding-right: 15vw;
        padding-bottom: 20px;
    }

    #footer-background {
        height: 200px;
    }

    #copyright,
    .copyright {
        font-size: 3vw;
        margin-top: -10px;
    }

    .hideBreak {
        display: none;
    }

    .footerParagraph {
        max-width: 82% !important;
    }

    .footerTitle {
        font-size: 7vw;
    }
}

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) and (max-width: 767.98px) {
    .footerTitle {
        font-size: 7vw;
    }

    #footerCopy {
        font-size: 1em;
        line-height: 1.4;
        margin-top: -28%;
        margin-left: 15%;
    }

    #myFooter {
        height: 950px;
        padding-top: 39%;
        padding-right: 15vw;
    }

    #footer-background {
        height: 170px;
        bottom: -25%;
    }

    .hideBreak {
        display: none;
    }

    #copyright,
    .copyright {
        font-size: 2vw;
    }

    .footerParagraph {
        max-width: 85% !important;
    }
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991.98px) {
    #copyright,
    .copyright {
        font-size: 1.5vw;
        margin-top: -2vw;
    }

    #myFooter {
        padding-top: 0%;
        height: 770px;
        font-size: 1.3vw;
    }

    #footer-background {
        bottom: 0%;
        height: 200px;
    }

    .footerParagraph {
        max-width: 70% !important;
        padding: 0;
        margin-left: 11%;
    }

    .footerTitle {
        margin-left: 11%;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1498px) {
    #footer-background {
        height: 200px;
    }

    #copyright,
    .copyright {
        font-size: 1vw;
    }

    #myFooter {
        padding-top: 0%;
        height: 1200px;
    }

    .footerParagraph {
        max-width: 70% !important;
        margin-left: 3%;
    }

    .footerTitle {
        margin-left: 3%;
    }
}

/* Extra large devices (large desktops, 1499px and up) */
@media (min-width: 1499px) {
    #footer-background {
        height: 240px;
    }
}